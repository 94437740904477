



















































import Vue from "vue";
import moment from "moment";
import { Appointment, Day } from "@/components/modules/types/Calendar";
import { mapState } from "vuex";
import i18n from "@/i18n/index";
import CalendarHelper from "@/helpers/CalendarHelper";
const calendarHelper = new CalendarHelper();

interface CalendarData {
  currentDate: string;
}

export default Vue.extend({
  props: {
    appointments: calendarHelper.getAppointmentsPropValidator(),
    selectedWeek: {
      type: String,
      default: function () {
        return moment().format();
      },
    },
    selectedAppointmentId: {
      type: String,
      default: "",
    },
  },
  data(): CalendarData {
    return {
      currentDate: moment().format(),
    };
  },
  computed: {
    ...mapState(["appointmentError"]),
    calendarToDisplay(): Array<Day> {
      const today = this.$moment();
      moment.locale(i18n.locale);
      const firstDayOfThisWeek = this.$moment(this.selectedWeek)
        .clone();
      if (!firstDayOfThisWeek) return [];
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = firstDayOfThisWeek.clone().add(i, "days");

        const appointmentsForThisDay: Array<Appointment> = [];
        this.appointments.forEach((appointment: Appointment) => {
          if (day.isSame(this.$moment(appointment.start), "day")) {
            appointment.timeslot =
              this.$moment(appointment.start).format("HH:mm") +
              " - " +
              this.$moment(appointment.end).format("HH:mm") +
              this.getClock();
            appointmentsForThisDay.push(appointment);
          }
        });

        days.push({
          dayOfMonth: day.date(),
          isToday: day.isSame(today, "day"),
          appointments: appointmentsForThisDay,
          isRecommended: appointmentsForThisDay.some(
            (appointment) => appointment.isRecommendation
          ),
          date: calendarHelper.getFormattedDate(day),
          display: day.format(`ddd, ${calendarHelper.displayDateFormat}`),
        });
      }

      return days;
    },
  },
  methods: {
    getClock() {
      if (i18n.locale === "pl") {
        return "";
      }
      return this.$t("general.clock");
    },
  },
});
